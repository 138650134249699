import React from 'react';
import { Link } from 'react-router-dom';
import userProfileIcon from '../../assets/images/userProfileIcon.png';
import userPassIcon from '../../assets/images/userPassIcon.png';

function GroupCard(props) {
  const { authority, contact, email, total_users } = props.user;
  const onEditUser = (event) => {
    event.preventDefault();
    props.onEditUser(props.user);
  };

  const onDeleteUser = event => {
    event.preventDefault();
    props.onDeleteUser(props.user);
  }

  return (
    <div className='user-card-container'>
      <div className='user-card'>
        <div className='user-card-profile'>
          <img src={userProfileIcon} className='user-profile-icon' alt='User' />
          <div className='user-profile-info'>
            <Link to="usermanagement/edituser" onClick={onEditUser} className='text-black font-bold '>{authority}</Link>
            <p>{contact}</p>
            <p>{email}</p>
          </div>
        </div>
        <div className='total-user-container'>
          <img src={userPassIcon} className='total-user-icon' alt='Total user icon' />
          <p>{total_users}</p>
        </div>
      </div>
      <div className='user-btn-container'>
        <button type='submit' className='edit-user-btn' style={{ marginRight: '1rem' }} onClick={onEditUser}>Edit User Group</button>
        <button type='submit' className='delete-user-btn' onClick={onDeleteUser}>Delete User Group</button>
      </div>
    </div>
  );
}

export default GroupCard;
