import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { notify, errorNotification } from '../../services/notification';
import {
    createCouncilUserSuccess,
    createCouncilUserError,
    getModulesSuccess,
    getModulesError,
    getCouncilUsersSuccess,
    getCouncilUsersError,
    getCouncilUserSuccess,
    getCouncilUserError,
    updateCouncilUserSuccess,
    updateCouncilUserError,
    deleteCouncilUserSuccess,
    deleteCouncilUserError,
    councilApiLoading,
    clearCouncil,
    managerLoginError,
    managerLoginSuccess
} from './actions'

const initialState = fromJS({
  modules: null,
  councilUsers: null,
  councilProfile: null,
  isLoading: false,
  token: null
})

const reducer = handleActions(
  {
    [getModulesSuccess] (state, { payload }) {
      return state
        .set('modules', payload.data)
        .set('isLoading', false)
    },
    [managerLoginSuccess] (state, { payload }) {
      localStorage.setItem('token', payload.data);
      return state
        .set('token', payload.data)
        .set('isLoading', false)
    },
    [managerLoginError] (state) {
      errorNotification('Invalid user')
      return state.set('isLoading', false)
    },
    [councilApiLoading] (state) {
      return state.set('isLoading', true)
    },
    [clearCouncil] (state) {
      return state.set('isLoading', false)
      .set('modules', null)
      .set('councilUsers', null)
    },
    [getModulesError] (state) {
      errorNotification('Unable to get topic data')
      return state.set('isLoading', false)
    },
    [getCouncilUsersSuccess] (state, { payload }) {
      return state
        .set('councilUsers', payload.data)
        .set('isLoading', false)
    },
    [getCouncilUserSuccess] (state, { payload }) {
      return state
        .set('councilProfile', payload.data)
        .set('isLoading', false)
    },
    [getCouncilUsersError] (state, { payload }) {
      errorNotification(payload || 'Unable to get users')
      return state.set('isLoading', false)
    },
    [getCouncilUserError] (state, { payload }) {
      errorNotification(payload || 'Unable to get profile information!')
      return state.set('isLoading', false)
    },

    [updateCouncilUserSuccess] (state) {
      notify('Successfully updated user')
      return state.set('isLoading', false)
    },
    [updateCouncilUserError] (state, { payload }) {
      errorNotification(payload || 'Failed to udpate user. Please try again later.')
      return state.set('isLoading', false)
    },
    [deleteCouncilUserSuccess] (state) {
      notify('Successfully deleted user')
      return state.set('isLoading', false)
    },
    [deleteCouncilUserError] (state, { payload }) {
      errorNotification(payload || 'Failed to delete user. Please try again later.')
      return state.set('isLoading', false)
    },

    [createCouncilUserSuccess] (state) {
      notify('Successfully added a user.')
      return state.set('isLoading', false)
    },
    [createCouncilUserError] (state, { payload }) {
      errorNotification(payload || 'User already exists with that email or authority name')
      return state.set('isLoading', false)
    },
  },
  initialState
)

export default reducer
