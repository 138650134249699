import { get, post, put as put_call, deleteRequest } from '../../services/request'
import { APP_HOST } from '../../config'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  createAuthorityUserSuccess,
  createAuthorityUserError,
  CREATE_AUTHORITY_USER,

  updateAuthorityUserSuccess,
  updateAuthorityUserError,
  UPDATE_AUTHORITY_USER,

  getAuthorityUsersSuccess,
  getAuthorityUsersError,
  GET_AUTHORITY_USERS,

  deleteAuthorityUserSuccess,
  deleteAuthorityUserError,
  DELETE_AUTHORITY_USER,
  authorityApiLoading,
  clearAuthority,
  CLEAR_AUTHORITY,
} from './actions'

export function * clearAuthorityUser () {
  yield put({ type: clearAuthority})
}

export function * deleteAuthorityUser ({ payload }) {
  try {
    yield put({ type: authorityApiLoading})
    const res = yield call(deleteRequest, `${APP_HOST}/api/authority/${payload.id}`)
    yield put(deleteAuthorityUserSuccess(res))
    yield call(getAuthorityUsers, {payload: {id: payload.council_user_id}})
  } catch (error) {
    yield put(deleteAuthorityUserError(error.message.replace('Error: ')))
  }
}

export function * getAuthorityUsers ({ payload }) {
  try {
    yield put({ type: authorityApiLoading})
    const res = yield call(get, `${APP_HOST}/api/authority/${payload.id}/users`)
    yield put(getAuthorityUsersSuccess(res))
  } catch (error) {
    yield put(getAuthorityUsersError(error.message))
  }
}

export function * createAuthorityUser ({ payload }) {
  try {
    yield put({ type: authorityApiLoading})
    const res = yield call(post, `${APP_HOST}/api/authority`, payload)
    yield put(createAuthorityUserSuccess(res))
    yield call(getAuthorityUsers, {payload: {id: payload.council_user_id}})
  } catch (error) {
    yield put(createAuthorityUserError(error.message.replace('Error: ')))
  }
}

export function * updateAuthorityUser ({ payload }) {
  try {
    yield put({ type: authorityApiLoading})
    const res = yield call(put_call, `${APP_HOST}/api/authority`, payload)
    yield put(updateAuthorityUserSuccess(res))
    yield call(getAuthorityUsers, {payload: {id: payload.council_user_id}})
  } catch (error) {
    yield put(updateAuthorityUserError(error.message.replace('Error: ')))
  }
}

export default function * sagas () {
  yield takeEvery(CLEAR_AUTHORITY, clearAuthorityUser)
  yield takeEvery(DELETE_AUTHORITY_USER, deleteAuthorityUser)
  yield takeEvery(GET_AUTHORITY_USERS, getAuthorityUsers)
  yield takeEvery(CREATE_AUTHORITY_USER, createAuthorityUser)
  yield takeEvery(UPDATE_AUTHORITY_USER, updateAuthorityUser)
}
