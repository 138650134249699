import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { notify, errorNotification } from '../../services/notification';
import {
  companyApiLoadingAction,
  getCompanyListSuccessAction,
  getCompanyListErrorAction,
  createNewCompanySuccessAction,
  createNewCompanyErrorAction,
  deleteCompanySuccessAction,
  deleteCompanyErrorAction,
  getCompanySuccessAction,
  getCompanyErrorAction,
  updateCompanySuccessAction,
  updateCompanyErrorAction,
} from './actions'

const initialState = fromJS({
  companyList: null,
  companyData: null,
  isLoading: false,
  token: null
})

const reducer = handleActions(
  {
    [companyApiLoadingAction] (state) {
      return state.set('isLoading', true)
    },
    [getCompanyListSuccessAction] (state, { payload }) {
      return state
        .set('companyList', payload.data)
        .set('isLoading', false)
    },
    [getCompanyListErrorAction] (state, { payload }) {
      errorNotification(payload || 'Unable to get companies')
      return state.set('isLoading', false)
    },
    [createNewCompanySuccessAction] (state) {
      notify('Successfully added new company.')
      return state.set('isLoading', false)
    },
    [createNewCompanyErrorAction] (state, { payload }) {
      errorNotification(payload || 'Company already exists with that email')
      return state.set('isLoading', false)
    },
    [getCompanySuccessAction] (state, { payload }) {
      return state
        .set('companyData', payload.data)
        .set('isLoading', false)
    },
    [getCompanyErrorAction] (state, { payload }) {
      errorNotification(payload || 'Company does not exist!')
      return state.set('isLoading', false)
    },
    [deleteCompanySuccessAction] (state) {
      notify('Successfully deleted the company.')
      return state.set('isLoading', false)
    },
    [deleteCompanyErrorAction] (state, { payload }) {
      errorNotification(payload || 'Something went wrong!')
      return state.set('isLoading', false)
    },
    [updateCompanySuccessAction] (state) {
      notify('Successfully updated the company.')
      return state
    },
    [updateCompanyErrorAction] (state, { payload }) {
      errorNotification(payload || 'Something went wrong!')
      return state
    },
  },
  initialState
)

export default reducer
