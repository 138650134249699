import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'
import addUserIcon from '../../assets/images/addUserIcon.png'
import Skeleton from '@material-ui/lab/Skeleton'

import {
  getModules,
  createCouncilUser
} from '../../reducers/counciluser/actions'
import { errorNotification } from '../../services/notification'
import { isValidEmail } from '../../services/utils'
import { auth } from '../../services/firebase'

const ModuleContainer = styled.div`
  position: relative;
  width: 182px;
  min-width: 182px;
  height: 250px;
  background-size: cover;
  background-position: center;
  border: 1px solid #bfbaba;
  border-radius: 5px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 1rem;
`

function AddGroup(props) {
  const {isLoading, modules, getModules, createCouncilUser, history, match } = props;
  const [topic_bundle_ids, setTopics] = useState({});
  const [authorityName, setAuthorityName] = useState('');
  const [authorityContact, setAuthorityContact] = useState('');
  const [authorityEmail, setAuthorityEmail] = useState('');
  const [totalUsers, setTotalUsers] = useState('');

  useEffect(() => {
    if (!match.params || !match.params.uuid || !parseInt(match.params.uuid, 10)) {
      errorNotification('You do not have proper permission to this Group Page!')
      history.push('/')
    }
  }, [match, history])

  useEffect(() => {
    if (!modules) {
      getModules();
    }
  }, [modules, getModules])

  const handleChange = (event, id) => {
    setTopics({ ...topic_bundle_ids, [id]: event.target.checked });
  };

  const onCancel = e => {
    e.preventDefault()
    if (auth.currentUser?.displayName === 'company') {
      history.push(`/companymanagement/view`)
    } else {
      if (match.params.uuid) {
        history.push(`/companymanagement/${match.params.uuid}/edit`)
      } else {
        history.push('/')
      }
    }
  }

  const onAddUser = e => {
    e.preventDefault()
    let topicIds = ""
    for (let topicId in topic_bundle_ids) {
      const isChecked = topic_bundle_ids[topicId]
      if (isChecked) {
        topicIds += topicIds === "" ? topicId : `,${topicId}`
      }
    }
    if (!authorityContact || !authorityEmail || !authorityName || !totalUsers) {
      return errorNotification('Please fill missing fields')
    }
    if (authorityEmail && !isValidEmail(authorityEmail)) {
      return errorNotification('Please input valid email address!')
    }
    if (topicIds === "") {
      return errorNotification('At least one more topic bundle needs to be added!')
    }

    const user = {
      contact: authorityContact,
      email: authorityEmail,
      authority: authorityName,
      total_users: totalUsers,
      topic_bundle_ids: topicIds,
      company_id: parseInt(match.params.uuid, 10),
    }

    if (authorityContact && authorityEmail && authorityName && totalUsers && topicIds && parseInt(match.params.uuid, 10)) {
      createCouncilUser(user)
    }
  }

  const onChangeHandler = (event) => {
    event.preventDefault()
    const { name, value } = event.currentTarget
    if (name === 'authorityName') {
      setAuthorityName(value)
    }
    else if (name === 'authorityContact') {
      setAuthorityContact(value)
    } else if (name === 'authorityEmail') {
      setAuthorityEmail(value)
    } else if (name === 'totalUsers') {
      setTotalUsers(value)
    }
  };

  const getSelectedTopics = () => {
    let topic_count = 0;
    if (topic_bundle_ids) {
      const realIds = modules ? modules.map(item => item.id) : [];
      Object.keys(topic_bundle_ids).forEach(item => {
        if (realIds.length > 0 && topic_bundle_ids[item] && realIds.includes(item)) {
          topic_count++;
        }
      })
    }
    return topic_count;
  }

  return (
    <div className="container">
      <div className=''>
        <Link to='/usermanagement/adduser' className='user-btn' style={{ marginLeft: '2rem' }}>
          <img src={addUserIcon} alt="Add user" className='add-user-icon' /> <h1 style={{ fontSize: '2.5rem'}}>Add User Group</h1>
        </Link>
        <ToastContainer />
        <div className='add-user-form'>
          <div className='form-field'>
            <label>User Group Name:</label>
            {isLoading
              ? (
                  <div  style={{ width: '50%', maxWidth: '50%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : <input type="text" name='authorityName' value={authorityName} onChange={(event) => onChangeHandler(event)} />
            }
          </div>
          <div className='form-field'>
            <label>User Group Contact:</label>
            {isLoading
              ? (
                  <div  style={{ width: '50%', maxWidth: '50%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : <input type="text" name='authorityContact' value={authorityContact} onChange={(event) => onChangeHandler(event)} />
            }
          </div>
          <div className='form-field'>
            <label>Email Address</label>
            {isLoading
              ? (
                  <div  style={{ width: '50%', maxWidth: '50%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : <input type="email" name='authorityEmail' value={authorityEmail} onChange={(event) => onChangeHandler(event)} />
            }
          </div>
          <div className='form-field'>
            <label>Number of User Passes</label>
            {isLoading
              ? (
                  <div  style={{ width: '50%', maxWidth: '50%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : <input type="number" name='totalUsers' value={totalUsers} onChange={(event) => onChangeHandler(event)} />
            }
          </div>
          <div className='form-field'>
            <label>Topic Bundles Included ({getSelectedTopics()} / {(modules && modules.length) || 0}) :</label>
          </div>
          <div className='form-field'>
            <FormControl component="fieldset" style={{width: '100%'}}>
              <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%'}}>
                {!isLoading && !modules && <p>No topic found!</p>}
                {isLoading ? (
                  <div  style={{ width: '100%', maxWidth: '100%', marginTop: '2rem'}}>
                    <Skeleton />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </div>
                ) : (
                  <>
                    {modules && modules.map(module => {
                      const {id, title, background, coverImage, isPremium} = module
                      return (
                        <ModuleContainer key={id}
                          style={{
                            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.17) 29.08%, rgba(93, 100, 171, 0.5) 100%),url(${coverImage})`,
                            boxShadow: `5px 7px 0px 2px ${background}`,
                            border: isPremium ?  (topic_bundle_ids[id] ? '2px solid green' : '2px solid red') : '2px solid #3e3263',
                            color: isPremium ? (topic_bundle_ids[id] ? 'green' : 'red') : '#3e3263'
                          }}>
                          <FormControlLabel
                            style={{ float: 'right'}}
                            value="top"
                            control={<Switch checked={topic_bundle_ids[id] || false} onChange={e => handleChange(e, id)} />}
                            label={''}
                            labelPlacement="start"
                          />
                          <p style={{ position: 'absolute', left: '10px', bottom: '8px', fontSize: '13px', color: 'white'}}>{title}</p>
                        </ModuleContainer>
                      )
                    })}
                  </>
                ) }
              </div>
            </FormControl>
          </div>
          <div>
            <button type='submit' className='text-white submit-btn' onClick={onAddUser} style={{ marginRight: '1rem' }}>Create User Group</button>
            <button type='submit' className='text-white submit-btn' onClick={onCancel}>Go Back</button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  flash: state.getIn(['councilUserReducer', 'flash']),
  modules: state.getIn(['councilUserReducer', 'modules']),
  isLoading: state.getIn(['councilUserReducer', 'isLoading']),
})
  
const mapDispatchToProps = dispatch => ({
  createCouncilUser: params => {
    dispatch(createCouncilUser(params))
  },
  getModules: () => {
    dispatch(getModules())
  }
})

const Container = compose(connect(mapStateToProps, mapDispatchToProps))(AddGroup)

export default Container
