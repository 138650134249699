import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { notify, errorNotification } from '../../services/notification';
import {
    createAuthorityUserSuccess,
    createAuthorityUserError,

    updateAuthorityUserSuccess,
    updateAuthorityUserError,

    getAuthorityUsersSuccess,
    getAuthorityUsersError,

    deleteAuthorityUserSuccess,
    deleteAuthorityUserError,
    authorityApiLoading,
    clearAuthority,
} from './actions'

const initialState = fromJS({
    modules: null,
    authorityUsers: null,
    flash: null,
    isLoading: false
})

const reducer = handleActions(
    {
        [getAuthorityUsersSuccess](state, { payload }) {
            return state
                .set('authorityUsers', payload.data)
                .set('isLoading', false)
        },
        [authorityApiLoading] (state) {
            return state.set('isLoading', true)
          },
        [clearAuthority] (state) {
            return state.set('isLoading', false)
                .set('modules', null)
                .set('authorityUsers', null)
                .set('flash', null)
          },
        [getAuthorityUsersError](state, { payload }) {
            errorNotification(payload || 'Unable to get users data!')
            return state.set('isLoading', false)
        },

        [updateAuthorityUserSuccess](state) {
            notify('Successfully updated user')
            return state.set('isLoading', false)
        },
        [updateAuthorityUserError](state, { payload }) {
            errorNotification(payload || 'Failed to udpate user. Please try again later.')
            return state.set('isLoading', false)
        },

        [deleteAuthorityUserSuccess](state) {
            notify('Successfully removed user')
            return state.set('isLoading', false)
        },
        [deleteAuthorityUserError](state, { payload }) {
            errorNotification(payload || 'Failed to remove user. Please try again later.')
            return state.set('isLoading', false)
        },

        [createAuthorityUserSuccess](state) {
            notify('Successfully added a user.')
            return state.set('isLoading', false)
        },
        [createAuthorityUserError](state, { payload }) {
            errorNotification(payload || 'User already exists with that email')
            return state.set('isLoading', false)
        },
    },
    initialState
)

export default reducer
