import React from 'react';
import { Link } from 'react-router-dom';
import companyLogoIcon from '../../assets/images/company-logo.png';

function CompanyCard(props) {
  const {
    companyDetails: { companyName, contactName, contactEmail },
    onEditCompany,
    onDeleteCompany
  } = props;
  const onEdit = (event) => {
    event.preventDefault();
    onEditCompany(props.companyDetails);
  };

  const onDelete = event => {
    event.preventDefault();
    onDeleteCompany(props.companyDetails);
  }

  return (
    <div className='user-card-container'>
      <div className='user-card'>
        <div className='user-card-profile'>
          <img src={companyLogoIcon} className='user-profile-icon' alt='User' />
          <div className='user-profile-info'>
              <Link to="companymanagement/view" onClick={onEdit} className='text-black font-bold '>{companyName}</Link>
              <p>{contactName}</p>
              <p>{contactEmail}</p>
          </div>
        </div>
      </div>
      <div className='user-btn-container'>
        <button type='submit' className='edit-user-btn' style={{ marginRight: '1rem' }} onClick={onEdit}>Edit Company</button>
        <button type='submit' className='delete-user-btn' onClick={onDelete}>Delete Company</button>
      </div>
    </div>
  );
}

export default CompanyCard;
