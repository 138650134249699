import { Link } from 'react-router-dom';
import addUserIcon from '../../assets/images/addUserIcon.png';
import manageUsersIconActive from '../../assets/images/manageUsersIconActive.png';

function CompanyMain() {

  return (
    <div className="container">
      <div className='home-container'>
        <div>
          <Link to='/companymanagement/add' className='user-btn'>
            <img src={addUserIcon} alt="Add Company" className='add-user-icon'/> <h1 style={{ fontSize: '2.5rem'}}>Add New Company</h1>
          </Link>
          <Link to='/companymanagement/manage' className='user-btn' style={{ marginTop: '2rem'}}>
            <img src={manageUsersIconActive} alt="Manage Company" className='manage-user-icon' /> <h1 style={{ fontSize: '2.5rem'}}>Manage Companies</h1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CompanyMain;
