import { createAction } from 'redux-actions'

export const COMPANY_API_LOADING = 'COMPANY_API_LOADING'

export const CREATE_NEW_COMPANY = 'CREATE_NEW_COMPANY'
export const CREATE_NEW_COMPANY_SUCCESS = 'CREATE_NEW_COMPANY_SUCCESS'
export const CREATE_NEW_COMPANY_ERROR = 'CREATE_NEW_COMPANY_ERROR'

export const DELETE_COMPANY = 'DELETE_COMPANY'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR'

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST'
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS'
export const GET_COMPANY_LIST_ERROR = 'GET_COMPANY_LIST_ERROR'

export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR'

export const GET_COMPANY_BY_EMAIL = 'GET_COMPANY_BY_EMAIL'
export const GET_COMPANY_BY_EMAIL_SUCCESS = 'GET_COMPANY_BY_EMAIL_SUCCESS'
export const GET_COMPANY_BY_EMAIL_ERROR = 'GET_COMPANY_BY_EMAIL_ERROR'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR'

export const companyApiLoadingAction = createAction(COMPANY_API_LOADING)

export const createNewCompanyAction = createAction(CREATE_NEW_COMPANY)
export const createNewCompanySuccessAction = createAction(CREATE_NEW_COMPANY_SUCCESS)
export const createNewCompanyErrorAction = createAction(CREATE_NEW_COMPANY_ERROR)

export const getCompanyListAction = createAction(GET_COMPANY_LIST)
export const getCompanyListSuccessAction = createAction(GET_COMPANY_LIST_SUCCESS)
export const getCompanyListErrorAction = createAction(GET_COMPANY_LIST_ERROR)

export const deleteCompanyAction = createAction(DELETE_COMPANY)
export const deleteCompanySuccessAction = createAction(DELETE_COMPANY_SUCCESS)
export const deleteCompanyErrorAction = createAction(DELETE_COMPANY_ERROR)

export const getCompanyAction = createAction(GET_COMPANY)
export const getCompanySuccessAction = createAction(GET_COMPANY_SUCCESS)
export const getCompanyErrorAction = createAction(GET_COMPANY_ERROR)

export const getCompanyByEmailAction = createAction(GET_COMPANY_BY_EMAIL)
export const getCompanyByEmailSuccessAction = createAction(GET_COMPANY_BY_EMAIL_SUCCESS)
export const getCompanyByEmailErrorAction = createAction(GET_COMPANY_BY_EMAIL_ERROR)

export const updateCompanyAction = createAction(UPDATE_COMPANY)
export const updateCompanySuccessAction = createAction(UPDATE_COMPANY_SUCCESS)
export const updateCompanyErrorAction = createAction(UPDATE_COMPANY_ERROR)