/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Link } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import companyLogoIcon from '../../assets/images/company-logo.png'
import manageUsersIconActive from '../../assets/images/manageUsersIconActive.png'

import {
  getCompanyAction,
  updateCompanyAction
} from '../../reducers/company/actions'
import {
  getCouncilUsers as getCouncilUsersAction,
  deleteCouncilUser as deleteCouncilUserAction,
} from '../../reducers/counciluser/actions'
import { errorNotification } from '../../services/notification'
import { isValidEmail } from '../../services/utils'
import GroupCard from '../../components/cards/GroupCard'

type CompanyData = {
  id: number;
  companyName: string;
  contactEmail: string;
  contactName: string;
}
const CompanyInfoDump: CompanyData = {
  id: 0,
  companyName: '',
  contactEmail: '',
  contactName: ''
}

function EditCompany(props) {
  const {
    companyDetails,
    getCompanyById,
    updateCompanyDetails,
    isLoading,
    isGroupLoading,
    getGroups,
    deleteGroupById,
    match,
    history,
    groups,
  } = props

  const [companyInfo, setCompanyInfo] = useState<CompanyData>(CompanyInfoDump)
  const [isCompanyDetailsChanged, setCompanyDetailsChanged] = useState(false)
  const [open, setOpen] = useState(false)
  const [groupId, setGroupId] = useState(null)
  const [groupName, setGroupName] = useState(null)

  useEffect(() => {
    if (match.params && match.params.uuid) {
      getCompanyById({
        id: match.params.uuid,
        history
      })
      getGroups({ companyId: match.params.uuid })
    } else {
      errorNotification('You do not have proper permission to Company Edit Page!')
      history.push('/')
    }
  }, [])

  useEffect(() => {
    if (!isLoading && companyDetails) {
      setCompanyInfo(companyDetails)
    }
  }, [isLoading, companyDetails])

  const onCancel = e => {
    e.preventDefault()
    props.history.push('/companymanagement/manage')
  }

  const onUpdateCompany = e => {
    e.preventDefault()
    if (!companyInfo.id || !companyInfo.companyName || !companyInfo.contactName || !companyInfo.contactEmail) {
        return errorNotification('Please fill missing fields')
    }
    if (!isValidEmail(companyInfo.contactEmail)) {
        return errorNotification('Please input valid email address!')
    }

    updateCompanyDetails(companyInfo)

    setCompanyDetailsChanged(false)
  }

  const onChangeHandler = (event) => {
    event.preventDefault()
    const { name, value } = event.currentTarget;
    const _clone = JSON.parse(JSON.stringify(companyInfo))
    _clone[`${name}`] = value

    setCompanyInfo(_clone)
    setCompanyDetailsChanged(true)
  }

  const onEditGroup = (group) => {
    if (group.id && companyInfo.id) {
      props.history.push(`/groupmanagement/${companyInfo.id}/${group.id}/edit`)
    }
  }

  const onDelete = () => {
    if (groupId && companyInfo.id) {
      deleteGroupById({ id: groupId, companyId: companyInfo.id })
      handleDialogClose()
    }
  }

  const onDeleteGroup = group => {
    setGroupId(group.id)
    setGroupName(group.authority)
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
    setGroupId(null)
    setGroupName(null)
  }

  return (
    <div className="container">
      <div className=''>
        <div className='user-btn' style={{ marginLeft: '2rem' }}>
          <img src={companyLogoIcon} alt="Add company" className='add-user-icon' />
          {isLoading ? (
            <div  style={{ width: '30%', maxWidth: '30%'}}>
              <Skeleton animation="wave" />
            </div>
          ) : (<h1 style={{ fontSize: '2.5rem'}}>{companyInfo.companyName}</h1>)}
        </div>
        <ToastContainer />
        <div className='add-user-form'>
          <div className='form-field'>
            <label>Company Name:</label>
            {isLoading
              ? (
                  <div  style={{ width: '40%', maxWidth: '40%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : (
                  <input
                    type="text"
                    name='companyName'
                    value={companyInfo.companyName}
                    onChange={(event) => onChangeHandler(event)}
                    placeholder="Surrey Council"
                  />
                )
            }
          </div>
          <div className='form-field'>
            <label>Company Contact Name:</label>
            {isLoading
              ? (
                  <div  style={{ width: '40%', maxWidth: '40%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : (
                  <input
                    type="text"
                    name='contactName'
                    value={companyInfo.contactName}
                    onChange={(event) => onChangeHandler(event)}
                    placeholder="David Smith"
                  />
                )
            }
          </div>
          <div className='form-field'>
            <label>Company Contact Email Address:</label>
            {isLoading
              ? (
                  <div  style={{ width: '40%', maxWidth: '40%'}}>
                    <Skeleton animation="wave" />
                  </div>
                )
              : (
                  <input
                    type="email"
                    name='contactEmail'
                    value={companyInfo.contactEmail}
                    onChange={(event) => onChangeHandler(event)}
                    placeholder="david@between-us.com"
                  />
                )
            }
          </div>
          <div>
            <button
              type='submit'
              className={isCompanyDetailsChanged ? 'text-white submit-btn' : 'text-white submit-btn-disabled'}
              onClick={onUpdateCompany}
              style={{ marginRight: '1rem' }}
              disabled={!isCompanyDetailsChanged}
            >
              Update Company
            </button>
            <button type='submit' className='text-white submit-btn' onClick={onCancel}>Go Back</button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '3rem' }}>
        <div style={{ marginLeft: '2rem' }}>
          <div className='user-btn'>
            <img src={manageUsersIconActive} alt="Manage user" className='manage-user-icon' />
            <h1 style={{ fontSize: '2.5rem'}}>Manage User Groups</h1>
          </div>
        </div>
        {isGroupLoading || !groups ? (
          <div className='user-management-container' style={{ width: '700px', maxWidth: '80%', marginLeft: '2rem', marginTop: '2rem'}}>
            {[...Array(6)].map((item, index) => (
              <div key={index} style={{ width: '700px', maxWidth: '100%', marginTop: '2rem'}}>
                  <Skeleton />
                  <Skeleton animation={false} />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
              </div>
            ))}
          </div>
        ) : (
          <div className='user-management-container'>
            <div style={{ display: 'flex', justifyContent: 'flex-start', boxSizing: 'border-box', padding: '1rem'}}>
              <Link to={`/groupmanagement/${companyInfo.id}/add`} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}> Click here to add new user group</Link>
            </div>
            {groups && groups.map(group => (
              <GroupCard user={group} key={group.id} onDeleteUser={onDeleteGroup} onEditUser={onEditGroup} />
            ))}
            {groups && groups.length === 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70vh' }}>
                <p style={{ fontSize: '1.3rem', color: 'black'}}>No user data found.</p>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Are you sure you want to delete this user group - {groupName} ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This action is not reversible. All end-users currently added to the account will lose access to all content other than the basic content
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} variant="contained"  color="secondary">
            Delete User Group
          </Button>
          <Button onClick={handleDialogClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => ({
  isLoading: state.getIn(['companyReducer', 'isLoading']),
  isGroupLoading: state.getIn(['councilUserReducer', 'isLoading']),
  companyDetails: state.getIn(['companyReducer', 'companyData']),
  groups: state.getIn(['councilUserReducer', 'councilUsers'])
})

const mapDispatchToProps = dispatch => ({
  getCompanyById: params =>
    dispatch(getCompanyAction(params)),
  updateCompanyDetails: params =>
    dispatch(updateCompanyAction(params)),
  getGroups: params =>
    dispatch(getCouncilUsersAction(params)),
  deleteGroupById: params =>
    dispatch(deleteCouncilUserAction(params))
})

const Container = compose(connect(mapStateToProps, mapDispatchToProps))(EditCompany)

export default Container
