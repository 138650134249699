import React from 'react';
import { auth } from '../../services/firebase'
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { clearCouncil } from '../../reducers/counciluser/actions'
import { clearAuthority } from '../../reducers/authorityuser/actions'

function Header(props) {
    const code = (new URLSearchParams(window.location.search)).get("oobCode")
    const [authPage, setAuthpage] = React.useState(false)
    
    const logout = async () => {
        const { clearCouncil, clearAuthority } = props;
        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        clearCouncil();
        clearAuthority();
        await auth.signOut();
    }

    React.useEffect(() => {
        if (code) {
            setAuthpage(true)
        } else {
            setAuthpage(false)
        }
    }, [code])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
 
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    }

    if (authPage) return null;

    return (
        <header>
            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}

                    color="inherit"
                >
                    <AccountCircle fontSize="large" />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    style={{ marginTop: '2rem' }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {
                        handleClose();
                        logout();
                    }}>Log out</MenuItem>
                </Menu>
            </div>
        </header>
    );
}

const mapDispatchToProps = dispatch => ({
    clearCouncil: () => {
      dispatch(clearCouncil())
    },
    clearAuthority: () => {
      dispatch(clearAuthority())
    }
})
  

const Container = compose(connect(null, mapDispatchToProps))(Header)

export default Container
