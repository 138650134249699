import { Link } from 'react-router-dom';
import TaviLogo from '../../assets/images/TaviLogo.png';

function NotFound () {
  return (
    <div className="login-page">
      <Link to="/" className="text-link">
        <img src={TaviLogo} className="App-logo" alt="logo" />
      </Link>
      <div style={{textAlign: 'center'}}>
        <h1 className="py-4 bg-red-600 w-full text-white text-center mb-3">Sorry, we can not find the page you are looking for!</h1>
      </div>
    </div>
  );
}

export default NotFound;
