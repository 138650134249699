import { combineReducers } from 'redux-immutable'

import councilUserReducer from './counciluser/reducer'
import authorityUserReducer from './authorityuser/reducer'
import companyReducer from './company/reducer'


export default combineReducers({
    councilUserReducer,
    authorityUserReducer,
    companyReducer,
})
