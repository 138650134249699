import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import manageUsersIconActive from '../../assets/images/manageUsersIconActive.png';
import CompanyCard from '../../components/cards/CompanyCard';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';

import { getCompanyListAction, deleteCompanyAction } from '../../reducers/company/actions'

function ManageCompany(props) {
  const { companyList, isLoading, getCompanyList, deleteCompanyById } = props;
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList])

  const [open, setOpen] = React.useState(false);

  const onEditCompany = (companyDetails) => {
    if (companyDetails.id) {
      props.history.push(`/companymanagement/${companyDetails.id}/edit`)
    }
  };

  const onDelete = () => {
    if (companyId) {
      deleteCompanyById({ id: companyId });
      handleClose();
    }
  }

  const onDeleteCompany = companyDetails => {
    setCompanyId(companyDetails.id);
    setCompanyName(companyDetails.companyName);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setCompanyId(null);
    setCompanyName(null);
  };

  return (
    <div className="container">
      <ToastContainer />
      <div>
        <div style={{ marginLeft: '2rem' }}>
          <Link to='/companymanagement/manage' className='user-btn'>
            <img src={manageUsersIconActive} alt="Manage company" className='manage-user-icon' /> <h1 style={{ fontSize: '2.5rem'}}>Manage Companies</h1>
          </Link>
        </div>
        {isLoading ? (
          <div className='user-management-container' style={{ width: '700px', maxWidth: '80%', marginLeft: '2rem', marginTop: '2rem'}}>
            {[...Array(6)].map((item, index) => (
              <div key={index} style={{ width: '700px', maxWidth: '100%', marginTop: '2rem'}}>
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ))}
          </div>
        ) : (
          <div className='user-management-container'>
            <div style={{ display: 'flex', justifyContent: 'flex-start', boxSizing: 'border-box', padding: '1rem'}}>
              <Link to='/companymanagement/add' style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}> Click here to add new company</Link>
            </div>
            {companyList && companyList.map(company => {
              return (
                <CompanyCard key={company.id} companyDetails={company} onDeleteCompany={onDeleteCompany} onEditCompany={onEditCompany} />
              )
            })}
            {companyList && companyList.length === 0 && 
             ( 
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70vh' }}>
                <p style={{ fontSize: '1.3rem', color: 'black'}}>No data found.</p>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">Are you sure you want to delete this company - {companyName} ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This action is not reversible. All groups and end users currently added to the company will lose access to all content other than the basic content
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} variant="contained"  color="secondary">
            Delete Company
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  companyList: state.getIn(['companyReducer', 'companyList']),
  isLoading: state.getIn(['companyReducer', 'isLoading']),
})

const mapDispatchToProps = dispatch => ({
  getCompanyList: () => {
    dispatch(getCompanyListAction())
  },
  deleteCompanyById: (params) => {
    dispatch(deleteCompanyAction(params))
  },
})

const Container = compose(connect(mapStateToProps, mapDispatchToProps))(ManageCompany)

export default Container;
