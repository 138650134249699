import React, { useState } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import addUserIcon from '../../assets/images/addUserIcon.png';

import {
  createNewCompanyAction
} from '../../reducers/company/actions'
import { errorNotification } from '../../services/notification';
import { isValidEmail } from '../../services/utils';

function AddCompany(props) {
  const { createNewCompany } = props;

  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const onCancel = e => {
    e.preventDefault();
    props.history.push('/companymanagement/manage')
  }

  const onAddCompany = e => {
    e.preventDefault();
    if (!companyName || !contactName || !contactEmail) {
        return errorNotification('Please fill missing fields')
    }
    if (contactEmail && !isValidEmail(contactEmail)) {
        return errorNotification('Please input valid email address!')
    }

    createNewCompany({
      data: {
        companyName,
        contactName,
        contactEmail,
      },
      history: props.history
    })
  }

  const onChangeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    if (name === 'companyName') {
      setCompanyName(value);
    }
    else if (name === 'contactName') {
      setContactName(value);
    } else if (name === 'contactEmail') {
      setContactEmail(value);
    }
  };

  return (
    <div className="container">
      <div className=''>
        <Link to='/companymanagement/add' className='user-btn' style={{ marginLeft: '2rem' }}>
          <img src={addUserIcon} alt="Add company" className='add-user-icon' /> <h1 style={{ fontSize: '2.5rem'}}>Add Company</h1>
        </Link>
        <ToastContainer />
        <div className='add-user-form'>
          <div className='form-field'>
            <label>Company Name:</label>
            <input
              type="text"
              name='companyName'
              value={companyName}
              onChange={(event) => onChangeHandler(event)}
              placeholder="Surrey Council"
            />
          </div>
          <div className='form-field'>
            <label>Company Contact Name:</label>
            <input
              type="text"
              name='contactName'
              value={contactName}
              onChange={(event) => onChangeHandler(event)}
              placeholder="David Smith"
            />
          </div>
          <div className='form-field'>
            <label>Company Contact Email Address:</label>
            <input
              type="email"
              name='contactEmail'
              value={contactEmail}
              onChange={(event) => onChangeHandler(event)}
              placeholder="david@between-us.com"
            />
          </div>
          <div>
            <button type='submit' className='text-white submit-btn' onClick={onAddCompany} style={{ marginRight: '1rem' }}>Create Company</button>
            <button type='submit' className='text-white submit-btn' onClick={onCancel}>Go Back</button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  createNewCompany: params => {
    dispatch(createNewCompanyAction(params))
  },
})

const Container = compose(connect(null, mapDispatchToProps))(AddCompany)

export default Container
