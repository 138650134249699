import { toast } from 'react-toastify';

export const notify = (msg) => toast(msg, {
    type: toast.TYPE.INFO,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export const errorNotification = (msg) => toast(msg, {
    type: toast.TYPE.ERROR,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});