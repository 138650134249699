import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import userProfileIcon from '../../assets/images/userProfileIcon.png';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import userPassIcon from '../../assets/images/userPassIcon.png';

import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

import {
  getAuthorityUsers,
  deleteAuthorityUser,
  updateAuthorityUser,
  createAuthorityUser,
} from '../../reducers/authorityuser/actions'
import {
  getCouncilUserByEmail,
  getModules
} from '../../reducers/counciluser/actions'
import { errorNotification } from '../../services/notification';
import { isValidEmail } from '../../services/utils';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
    maxWidth: '80%'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

function AuthorityUsers(props) {
  const { authorityUsers, getCouncilUser, getAuthorityUsers, modules, getModules, councilProfile, isCouncilApiLoading, isAuthorityApiLoading } = props;
  const { id, authority, contact, email, total_users, topic_bundle_ids } = councilProfile || {};

  const [chosenTopics, setChosenTopics] = useState<Array<any>>([])

  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const [isUpdateOpen, setUpdateOpen] = useState(false);

  const [userName, setUserName] = useState('');
  const [userEmail, setEmail] = useState('');
  const [userTopicBundleIds, setUserTopicBundleIds] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const [chosenUser, setChosenUser] = React.useState({});

  const classes = useStyles();

  useEffect(() => {
    getCouncilUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      getAuthorityUsers({ id });
    }
  }, [id, getAuthorityUsers])

  useEffect(() => {
    if (topic_bundle_ids) {
      const temp = topic_bundle_ids.split(',');
      let tempIds: Array<any> = [];
      temp.forEach((item) => {
        tempIds.push(item)
      })
      setChosenTopics(tempIds);
    }
  }, [topic_bundle_ids])

  useEffect(() => {
    if (!modules) {
      getModules();
    }
  }, [modules, getModules])

  const handleChange = (event, type) => {
    if (type === 'name') {
      setUserName(event.target.value)
    } else {
      setEmail(event.target.value)
    }
  }

  const handleClose = () => {
    setAddOpen(false);
    setDeleteOpen(false);
    setUpdateOpen(false);
    setChosenUser({});
    setUserName('');
    setEmail('');
    setUserTopicBundleIds('');
    setSelectAll(false);
  };

  const onAdd = () => {
    if (!userName || !userEmail) {
      return errorNotification('Please fill missing fields');
    }
    if (!isValidEmail(userEmail)) {
      return errorNotification('Please input valid email address!')
    }
    if (userTopicBundleIds === '') {
      return errorNotification('At least one more topic bundle needs to be added!')
    }
    props.createAuthorityUser({
      name: userName,
      email: userEmail,
      council_user_id: id,
      topic_bundle_ids: userTopicBundleIds
    });
    handleClose();
  }

  const onUpdate = () => {
    if (!userName || !userEmail) {
      return errorNotification('Please fill missing fields');
    }
    if (!isValidEmail(userEmail)) {
      return errorNotification('Please input valid email address!')
    }
    if (userTopicBundleIds === '') {
      return errorNotification('At least one more topic bundle needs to be added!')
    }
    if (userName && userEmail) {
      props.updateAuthorityUser({
          id: chosenUser['id'],
          name: userName,
          email: userEmail,
          council_user_id: id,
          topic_bundle_ids: userTopicBundleIds
      });
      handleClose();
    }
  }

  const onDelete = () => {
    if (chosenUser) {
      props.deleteAuthorityUser({ id: chosenUser['id'], council_user_id: id });
      handleClose();
    }
  }

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setUserTopicBundleIds(chosenTopics.join());
    } else {
      setUserTopicBundleIds('');
    }
  }

  const updateTopic = id => {
    if (id) {
      let topics = userTopicBundleIds ? userTopicBundleIds.split(',') : [];
      if (topics.includes(id)) {
        const index = topics.indexOf(id);
        if (index > -1) {
          topics.splice(index, 1);
          const newTopics = topics.join();
          setUserTopicBundleIds(newTopics);
        }
      } else {
        topics.push(id);
        const newTopics = topics.join();
        setUserTopicBundleIds(newTopics);
      }
      if (topics.length !== chosenTopics.length) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
    }
  }

  return (
    <div className="container">
      <ToastContainer />
      <div>
        <div style={{ marginLeft: '2rem' }}>
          <Link to='/usermanagement/users' className='user-btn'>
              <img src={userProfileIcon} alt="Council user" className='manage-user-icon' /> <p style={{ margin: 0, fontSize: '3rem'}}>{authority || 'Manage Users'}</p>
          </Link>
        </div>
        <div className='council-management-container'>
          <div className='council-user-container'>
              <div className='council-user-card'>
                  <div className='user-card-profile'>
                      <div style={{ padding: '1.4rem'}}>
                          <h1>{contact}</h1>
                          <h3 style={{ marginTop: '0.5rem'}}>{email}</h3>
                      </div>
                  </div>
                  <div className='council-total-user-container'>
                      <img src={userPassIcon} className='total-user-icon' alt='Total user icon' />
                      <p>{authorityUsers ? authorityUsers.length : 0} / {total_users}</p>
                  </div>
              </div>
              <div className='topic-list-container'>
                  <h2 style={{ minWidth: '200px' }}>Topic Bundles</h2>
                  {isCouncilApiLoading ? (
                      <div style={{ width: '100%'}}>
                          <Skeleton />
                          <Skeleton animation={false} />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                      </div>
                  ) : (
                      <div className='topics-container'>
                          {modules && modules.map(module => {
                              const { id, title, isPremium } = module
                              const elementStyle = {
                                  border: isPremium ?  (chosenTopics.includes(id) ? '2px solid green' : '2px solid red') : '2px solid #3e3263',
                                  color: isPremium ? (chosenTopics.includes(id) ? 'green' : 'red') : '#3e3263',
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  borderRadius: '5px',
                                  marginTop: '5px',
                                  padding: '5px 10px',
                                  fontSize: '12px'
                              };
                              return (
                                  <div style={elementStyle} key={id}>{title}</div>
                              )
                          })}
                      </div>
                  )}
              </div>
              {(!isCouncilApiLoading && modules && modules.length === 0) && <p>No topic found!</p>}
          </div>

          <div>
              <div style={{ float: 'right', margin: '1rem' }}>
                  <button className="submit-btn text-white" onClick={() => {
                      const isReachedMax = authorityUsers ? authorityUsers.length >= parseInt(total_users || 0, 10) : false
                      if (isReachedMax) {
                          return errorNotification('You already reached out max allowed users. Contact admin to assign more users.')
                      }
                      setAddOpen(true)
                  }}>Add user</button>
              </div>
              <TableContainer component={Paper} style={{padding: '1rem', boxSizing: 'border-box'}}>
                  <Table className={classes.table} aria-label="Authority user table">
                      <TableHead>
                          <TableRow>
                              <TableCell>No</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Email</TableCell>
                              <TableCell align="right">Bundles</TableCell>
                              <TableCell align="right">Settings</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {!isAuthorityApiLoading && authorityUsers && authorityUsers.map((user, index) => (
                              <TableRow key={user.name}>
                                  <TableCell component="th" scope="row">
                                      {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                      {user.name}
                                  </TableCell>
                                  <TableCell align="right">{user.email}</TableCell>
                                  <TableCell align="right">{(user.topic_bundle_ids || '').split(',').length + ' / ' + chosenTopics.length}</TableCell>
                                  <TableCell align="right">
                                      <IconButton aria-label="delete" onClick={e => {
                                          setDeleteOpen(true);
                                          setChosenUser(user)
                                      }}>
                                          <DeleteIcon />
                                      </IconButton>
                                      <IconButton aria-label="update" onClick={e => {
                                          setUpdateOpen(true);
                                          setChosenUser(user);
                                          setUserName(user.name);
                                          setEmail(user.email);
                                          if (user.topic_bundle_ids) {
                                              setUserTopicBundleIds(user.topic_bundle_ids);
                                          }
                                          if ((user.topic_bundle_ids || '').split(',').length === chosenTopics.length) {
                                              setSelectAll(true);
                                          } else {
                                              setSelectAll(false);
                                          }
                                      }}>
                                          <SaveIcon />
                                      </IconButton>
                                  </TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
                  {isAuthorityApiLoading && <div style={{ width: '100%'}}>
                          <Skeleton />
                          <Skeleton animation={false} />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                      </div>}
                  {(!isAuthorityApiLoading && authorityUsers && authorityUsers.length === 0) && <p style={{ textAlign: 'center', color: '#3e3263' }}>No data found</p>}
              </TableContainer>
          </div>
        </div>
      </div>

      <Dialog
        open={isAddOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Add User</DialogTitle>
        <DialogContent>
          <TextField
              id="standard-full-width"
              label="User Name"
              placeholder="Enter user name"
              fullWidth
              autoFocus
              margin="normal"
            
              value={userName}
              onChange={e => handleChange(e, 'name')}
          />
          <TextField
              id="standard-full-width"
              label="Email"
              placeholder="Enter user email"
              fullWidth
              margin="normal"
              autoFocus
            
              value={userEmail}
              onChange={e => handleChange(e, 'email')}
          />
          <div style={{ marginTop: '0.5rem'}}>
              <label>User Topic Bundles</label>
              <FormGroup>
                  <FormControlLabel
                      control={<Checkbox checked={selectAll} color="primary"
                          onChange={handleSelectAllChange} name="select" />}
                      label="Select All"
                  />
              </FormGroup>
              <div className='topics-container'>
                  {modules && modules.map(module => {
                      const { id, title } = module
                      if (! chosenTopics.includes(id)) return null;
                      const elementStyle = {
                          border: (userTopicBundleIds || '').split(',').includes(id) ? '2px solid green' : '2px solid #3e3263',
                          color: (userTopicBundleIds || '').split(',').includes(id) ? 'green' : '#3e3263',
                          marginRight: '5px',
                          borderRadius: '5px',
                          marginTop: '5px',
                          padding: '5px 10px',
                          fontSize: '12px',
                          cursor: 'pointer',
                      };
                      return (
                          <div style={elementStyle} key={id} onClick={e => updateTopic(id)}>{title}</div>
                      )
                  })}
              </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={onAdd}
          >
              Save
          </Button>
          <Button onClick={handleClose} variant="contained">
              Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isUpdateOpen}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Update User</DialogTitle>
        <DialogContent>
          <TextField
              id="standard-full-width"
              label="User Name"
              placeholder="Enter user name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={userName}
              onChange={e => handleChange(e, 'name')}
          />
          <TextField
              id="standard-full-width"
              label="Email"
              placeholder="Enter user email"
              fullWidth
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              value={userEmail}
              onChange={e => handleChange(e, 'email')}
          />
          <div style={{ marginTop: '0.5rem'}}>
              <label>User Topic Bundles</label>
              <FormGroup>
                  <FormControlLabel
                      control={<Checkbox checked={selectAll} color="primary"
                          onChange={handleSelectAllChange} name="select" />}
                      label="Select All"
                  />
              </FormGroup>
              <div className='topics-container'>
                  {modules && modules.map(module => {
                      const { id, title } = module
                      if (! chosenTopics.includes(id)) return null;
                      const elementStyle = {
                          border: (userTopicBundleIds || '').split(',').includes(id) ? '2px solid green' : '2px solid #3e3263',
                          color: (userTopicBundleIds || '').split(',').includes(id) ? 'green' : '#3e3263',
                          marginRight: '5px',
                          borderRadius: '5px',
                          marginTop: '5px',
                          padding: '5px 10px',
                          fontSize: '12px',
                          cursor: 'pointer',
                      };
                      return (
                          <div style={elementStyle} key={id} onClick={e => updateTopic(id)}>{title}</div>
                      )
                  })}
              </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onUpdate} variant="contained">
              Save
          </Button>
          <Button onClick={handleClose} variant="contained">
              Cancel
          </Button>
        </DialogActions>
        </Dialog>
        <Dialog
          open={isDeleteOpen}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title">Are you sure that you want to delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              This action is not reversible. this user will lose access to all content other than the basic content
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} variant="contained" color="secondary">
              Delete
          </Button>
          <Button onClick={handleClose} variant="contained">
              Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  flash: state.getIn(['councilUserReducer', 'flash']),
  modules: state.getIn(['councilUserReducer', 'modules']),
  councilProfile: state.getIn(['councilUserReducer', 'councilProfile']),
  isCouncilApiLoading: state.getIn(['councilUserReducer', 'isLoading']),
  authorityUsers: state.getIn(['authorityUserReducer', 'authorityUsers']),
  isAuthorityApiLoading: state.getIn(['authorityUserReducer', 'isLoading']),
})

const mapDispatchToProps = dispatch => ({
  getAuthorityUsers: (params) => {
    dispatch(getAuthorityUsers(params))
  },
  getModules: () => {
    dispatch(getModules())
  },
  getCouncilUser: () => {
    dispatch(getCouncilUserByEmail())
  },
  createAuthorityUser: (params) => {
    dispatch(createAuthorityUser(params))
  },
  updateAuthorityUser: (params) => {
    dispatch(updateAuthorityUser(params))
  },
  deleteAuthorityUser: (params) => {
    dispatch(deleteAuthorityUser(params))
  },
})

const Container = compose(connect(mapStateToProps, mapDispatchToProps))(AuthorityUsers)

export default Container;
