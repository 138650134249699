import { get, post, put as put_call, deleteRequest } from '../../services/request'
import { APP_HOST } from '../../config'
import { call, put, takeEvery } from 'redux-saga/effects'
// import { auth } from '../../services/firebase'
import {
  GET_COMPANY_LIST,
  CREATE_NEW_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY_BY_EMAIL,

  companyApiLoadingAction,
  createNewCompanySuccessAction,
  createNewCompanyErrorAction,

  getCompanyListSuccessAction,
  getCompanyListErrorAction,

  deleteCompanySuccessAction,
  deleteCompanyErrorAction,

  getCompanySuccessAction,
  getCompanyErrorAction,

  updateCompanySuccessAction,
  updateCompanyErrorAction
} from './actions'

export function * getCompanyListSaga () {
  try {
    yield put({ type: companyApiLoadingAction})
    const res = yield call(get, `${APP_HOST}/api/company`)
    yield put(getCompanyListSuccessAction(res))
  } catch (error) {
    yield put(getCompanyListErrorAction(error.message.replace('Error: ')))
  }
}

export function * createNewCompanySaga ({ payload }) {
  const { data, history } = payload
  try {
    yield put({ type: companyApiLoadingAction})
    const res = yield call(post, `${APP_HOST}/api/company/new`, data)
    yield put(createNewCompanySuccessAction(res))
    yield call(getCompanyListSaga)
    history.push('/companymanagement/manage')
  } catch (error) {
    yield put(createNewCompanyErrorAction(error.message.replace('Error: ')))
  }
}

export function * deleteCompanySaga ({ payload }) {
  try {
    yield put({ type: companyApiLoadingAction})
    const res = yield call(deleteRequest, `${APP_HOST}/api/company/${payload.id}`)
    yield put(deleteCompanySuccessAction(res))
    yield call(getCompanyListSaga)
  } catch (error) {
    yield put(deleteCompanyErrorAction(error.message.replace('Error: ')))
  }
}

export function * getCompanyByIdSaga ({ payload }) {
  const { id, history } = payload
  try {
    yield put({ type: companyApiLoadingAction})
    const res = yield call(get, `${APP_HOST}/api/company/${id}`)
    yield put(getCompanySuccessAction(res))
  } catch (error) {
    yield put(getCompanyErrorAction(error.message.replace('Error: ')))
    history.push('/')
  }
}

export function * getCompanyByEmailSaga () {
  try {
    yield put({ type: companyApiLoadingAction})
    const res = yield call(get, `${APP_HOST}/api/company-info`)
    yield put(getCompanySuccessAction(res))
  } catch (error) {
    yield put(getCompanyErrorAction(error.message.replace('Error: ')))
  }
}

export function * updateCompanySaga ({ payload }) {
  try {
    const res = yield call(put_call, `${APP_HOST}/api/company`, payload)
    yield put(updateCompanySuccessAction(res))
  } catch (error) {
    yield put(updateCompanyErrorAction(error.message.replace('Error: ')))
  }
}

export default function * sagas () {
  yield takeEvery(GET_COMPANY_LIST, getCompanyListSaga)
  yield takeEvery(CREATE_NEW_COMPANY, createNewCompanySaga)
  yield takeEvery(DELETE_COMPANY, deleteCompanySaga)
  yield takeEvery(GET_COMPANY, getCompanyByIdSaga)
  yield takeEvery(UPDATE_COMPANY, updateCompanySaga)
  yield takeEvery(GET_COMPANY_BY_EMAIL, getCompanyByEmailSaga)
}
