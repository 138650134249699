import { createAction } from 'redux-actions'

export const COUNCIL_API_LOADING = 'COUNCIL_API_LOADING'
export const CREATE_COUNCIL_USER = 'CREATE_COUNCIL_USER'
export const CREATE_COUNCIL_USER_SUCCESS = 'CREATE_COUNCIL_USER_SUCCESS'
export const CREATE_COUNCIL_USER_ERROR = 'CREATE_COUNCIL_USER_ERROR'

export const UPDATE_COUNCIL_USER = 'UPDATE_COUNCIL_USER'
export const UPDATE_COUNCIL_USER_SUCCESS = 'UPDATE_COUNCIL_USER_SUCCESS'
export const UPDATE_COUNCIL_USER_ERROR = 'UPDATE_COUNCIL_USER_ERROR'

export const GET_COUNCIL_USERS = 'GET_COUNCIL_USERS'
export const GET_COUNCIL_USERS_SUCCESS = 'GET_COUNCIL_USERS_SUCCESS'
export const GET_COUNCIL_USERS_ERROR = 'GET_COUNCIL_USERS_ERROR'

export const GET_COUNCIL_USER = 'GET_COUNCIL_USER'
export const GET_COUNCIL_USER_SUCCESS = 'GET_COUNCIL_USER_SUCCESS'
export const GET_COUNCIL_USER_ERROR = 'GET_COUNCIL_USER_ERROR'

export const GET_COUNCIL_USER_BY_EMAIL = 'GET_COUNCIL_USER_BY_EMAIL'
export const GET_COUNCIL_USER__BY_EMAIL_SUCCESS = 'GET_COUNCIL_USER__BY_EMAIL_SUCCESS'
export const GET_COUNCIL_USER__BY_EMAIL_ERROR = 'GET_COUNCIL_USER__BY_EMAIL_ERROR'

export const GET_MODULES = 'GET_MODULES'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR'

export const DELETE_COUNCIL_USER = 'DELETE_COUNCIL_USER'
export const DELETE_COUNCIL_USER_SUCCESS = 'DELETE_COUNCIL_USER_SUCCESS'
export const DELETE_COUNCIL_USER_ERROR = 'DELETE_COUNCIL_USER_ERROR'

export const CLEAR_COUNCIL = 'CLEAR_COUNCIL'

export const MANAGER_LOGIN = 'MANAGER_LOGIN'
export const MANAGER_LOGIN_SUCCESS = 'MANAGER_LOGIN_SUCCESS'
export const MANAGER_LOGIN_ERROR = 'MANAGER_LOGIN_ERROR'

export const managerLogin = createAction(MANAGER_LOGIN)
export const managerLoginSuccess = createAction(MANAGER_LOGIN_SUCCESS)
export const managerLoginError = createAction(MANAGER_LOGIN_ERROR)

export const councilApiLoading = createAction(COUNCIL_API_LOADING)

export const clearCouncil = createAction(CLEAR_COUNCIL)
export const createCouncilUser = createAction(CREATE_COUNCIL_USER)
export const createCouncilUserSuccess = createAction(CREATE_COUNCIL_USER_SUCCESS)
export const createCouncilUserError = createAction(CREATE_COUNCIL_USER_ERROR)

export const getCouncilUsers = createAction(GET_COUNCIL_USERS)
export const getCouncilUsersSuccess = createAction(GET_COUNCIL_USERS_SUCCESS)
export const getCouncilUsersError = createAction(GET_COUNCIL_USERS_ERROR)

export const getCouncilUser = createAction(GET_COUNCIL_USER)
export const getCouncilUserSuccess = createAction(GET_COUNCIL_USER_SUCCESS)
export const getCouncilUserError = createAction(GET_COUNCIL_USER_ERROR)

export const getCouncilUserByEmail = createAction(GET_COUNCIL_USER_BY_EMAIL)
export const getCouncilUserByEmailSuccess = createAction(GET_COUNCIL_USER__BY_EMAIL_SUCCESS)
export const getCouncilUserByEmailError = createAction(GET_COUNCIL_USER__BY_EMAIL_ERROR)

export const getModules = createAction(GET_MODULES)
export const getModulesSuccess = createAction(GET_MODULES_SUCCESS)
export const getModulesError = createAction(GET_MODULES_ERROR)

export const updateCouncilUser = createAction(UPDATE_COUNCIL_USER)
export const updateCouncilUserSuccess = createAction(UPDATE_COUNCIL_USER_SUCCESS)
export const updateCouncilUserError = createAction(UPDATE_COUNCIL_USER_ERROR)

export const deleteCouncilUser = createAction(DELETE_COUNCIL_USER)
export const deleteCouncilUserSuccess = createAction(DELETE_COUNCIL_USER_SUCCESS)
export const deleteCouncilUserError = createAction(DELETE_COUNCIL_USER_ERROR)
