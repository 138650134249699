import { createAction } from 'redux-actions'

export const AUTHORITY_API_LOADING = 'AUTHORITY_API_LOADING'
export const CREATE_AUTHORITY_USER = 'CREATE_AUTHORITY_USER'
export const CREATE_AUTHORITY_USER_SUCCESS = 'CREATE_AUTHORITY_USER_SUCCESS'
export const CREATE_AUTHORITY_USER_ERROR = 'CREATE_AUTHORITY_USER_ERROR'

export const UPDATE_AUTHORITY_USER = 'UPDATE_AUTHORITY_USER'
export const UPDATE_AUTHORITY_USER_SUCCESS = 'UPDATE_AUTHORITY_USER_SUCCESS'
export const UPDATE_AUTHORITY_USER_ERROR = 'UPDATE_AUTHORITY_USER_ERROR'

export const DELETE_AUTHORITY_USER = 'DELETE_AUTHORITY_USER'
export const DELETE_AUTHORITY_USER_SUCCESS = 'DELETE_AUTHORITY_USER_SUCCESS'
export const DELETE_AUTHORITY_USER_ERROR = 'DELETE_AUTHORITY_USER_ERROR'

export const GET_AUTHORITY_USERS = 'GET_AUTHORITY_USERS'
export const GET_AUTHORITY_USERS_SUCCESS = 'GET_AUTHORITY_USERS_SUCCESS'
export const GET_AUTHORITY_USERS_ERROR = 'GET_AUTHORITY_USERS_ERROR'

export const CLEAR_AUTHORITY = 'CLEAR_AUTHORITY'

export const authorityApiLoading = createAction(AUTHORITY_API_LOADING)
export const clearAuthority = createAction(CLEAR_AUTHORITY)
export const createAuthorityUser = createAction(CREATE_AUTHORITY_USER)
export const createAuthorityUserSuccess = createAction(CREATE_AUTHORITY_USER_SUCCESS)
export const createAuthorityUserError = createAction(CREATE_AUTHORITY_USER_ERROR)

export const getAuthorityUsers = createAction(GET_AUTHORITY_USERS)
export const getAuthorityUsersSuccess = createAction(GET_AUTHORITY_USERS_SUCCESS)
export const getAuthorityUsersError = createAction(GET_AUTHORITY_USERS_ERROR)

export const updateAuthorityUser = createAction(UPDATE_AUTHORITY_USER)
export const updateAuthorityUserSuccess = createAction(UPDATE_AUTHORITY_USER_SUCCESS)
export const updateAuthorityUserError = createAction(UPDATE_AUTHORITY_USER_ERROR)

export const deleteAuthorityUser = createAction(DELETE_AUTHORITY_USER)
export const deleteAuthorityUserSuccess = createAction(DELETE_AUTHORITY_USER_SUCCESS)
export const deleteAuthorityUserError = createAction(DELETE_AUTHORITY_USER_ERROR)
