import { fork, all } from 'redux-saga/effects'
import councilUserSaga from './counciluser/saga'
import authorityUserSaga from './authorityuser/saga'
import companySaga from './company/saga'

function * rootSagas () {
  yield all([
    fork(councilUserSaga),
    fork(authorityUserSaga),
    fork(companySaga),
  ])
}

export default rootSagas
