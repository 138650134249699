import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../services/firebase'
import { ToastContainer } from 'react-toastify';
import { errorNotification } from '../../services/notification'
import TaviLogo from '../../assets/images/TaviLogo.png';

function SignIn(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        if (user.displayName === 'council') {
          props.history.push('/usermanagement/users')
        } else if (user.displayName === 'company') {
          props.history.push('/companymanagement/view')
        } else {
          props.history.push('/companymanagement')
        }
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signIn = (event) => {
    event.preventDefault();
    if (!email && !password) {
      return errorNotification('Please fill missing fields')
    }
    if (!email || !password) {
      return errorNotification('Please fill missing field')
    }
    auth.signInWithEmailAndPassword(email, password).then(res => {
      if (res && res.user?.displayName === 'council') {
        props.history.push('/usermanagement/users');
      }
      if (res && res.user?.displayName === 'company') {
        props.history.push('/companymanagement/view');
      }
    }).catch(error => {
      console.log(error)
      errorNotification('Password is incorrect. please try again')
    })
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === 'userEmail') {
      setEmail(value);
    }
    else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return (
    <div className="login-page">
      <Link to="/" className="text-link">
        <img src={TaviLogo} className="App-logo" alt="logo" />
      </Link>
      <div className="signin-container">
        {error !== null && <div className="py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
        <ToastContainer />
        <form className="signin-form">
          <input
            type="email"
            className="input-form"
            name="userEmail"
            value={email}
            placeholder="Email Address"
            id="userEmail"
            style={{ }}
            onChange={(event) => onChangeHandler(event)}
          />
          <input
            type="password"
            className="input-form"
            name="userPassword"
            value={password}
            placeholder="Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <button className="submit-btn text-white" onClick={signIn}>
          LOGIN
          </button>
        </form>
        <p className="margin-0">
          <Link to="forgottenPassword" className="text-link">
            Forgotten Password?
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignIn;
