import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import './App.css'
import Login from './components/auth/signin'
import ForgotPassword from './components/auth/forgottenPassword'
import NotFound from './components/auth/notfound'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/header'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { auth } from './services/firebase'
import { ToastContainer } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'

// Company
import CompanyMain from './pages/company-management/main'
import AddCompany from './pages/company-management/add-company'
import ManageCompany from './pages/company-management/manage-company'
import EditCompany from './pages/company-management/edit-company'
import ViewCompany from './pages/company-management/view-company'

// Group
import EditGroup from './pages/group-management/edit-group'
import AddGroup from './pages/group-management/add-group'

import AuthorityUsers from './pages/user-management/manage-users'
import {
  managerLogin,
} from './reducers/counciluser/actions'

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 100vh;
  background: #ccccccd4;
  align-items: center;
`
enum ADMIN_TYPE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  GROUP_ADMIN = 'GROUP_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  NONE = 'NONE'
}
function App(props) {
  const { managerLogin } = props
  const [isAuthenticated, setAuthStatus] = useState(localStorage.getItem('auth') || false)
  const [adminType, setAdminType] = useState<String>(ADMIN_TYPE.NONE)
  const [isLoading, setLoading] = useState(true)

  const AuthRouter = (props) => {
    const { isAuthenticated, isGranted } = props
    return (
      <>
        {isAuthenticated
          ? isGranted 
            ? <Route exact {...props} />
            : <Redirect to="/not-found" />  
          : <Redirect to="/login" />
        }
      </>
    )
  }

  useEffect(() => {
    // detaching the listener
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setLoading(true)
        localStorage.setItem('auth', 'loggedin')
        const idToken = await auth.currentUser?.getIdToken(true)
        if (idToken) {
          localStorage.setItem('token', idToken)
          managerLogin({ token: idToken })

          if (user.displayName === 'council') {
            setAdminType(ADMIN_TYPE.GROUP_ADMIN)
          } else if (user.displayName === 'company') {
            setAdminType(ADMIN_TYPE.COMPANY_ADMIN)
          } else {
            setAdminType(ADMIN_TYPE.SUPER_ADMIN)
          }
          setAuthStatus(true)
          setLoading(false)
          return
        }
      }
      setAuthStatus(false)
      setLoading(false)
    })
    // unsubscribing from the listener when the component is unmounting. 
    return () => unsubscribe()
}, [managerLogin]);

  if (isLoading) {
    return <LoadingContainer><CircularProgress /></LoadingContainer>
  }

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        {isAuthenticated && <Header />}
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path='/login' component={Login} />
          <Route exact path='/forgottenPassword' component={ForgotPassword} />
          <Route path='/not-found' component={NotFound} />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.GROUP_ADMIN}
            path='/usermanagement/users'
            component={AuthorityUsers}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN || adminType === ADMIN_TYPE.COMPANY_ADMIN}
            path='/groupmanagement/:cuuid/:uuid/edit'
            component={EditGroup}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN || adminType === ADMIN_TYPE.COMPANY_ADMIN}
            path='/groupmanagement/:uuid/add'
            component={AddGroup}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.COMPANY_ADMIN}
            path='/companymanagement/view'
            component={ViewCompany}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN}
            path='/companymanagement/add'
            component={AddCompany}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN}
            path='/companymanagement/:uuid/edit'
            component={EditCompany}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN}
            path='/companymanagement/manage'
            component={ManageCompany}
          />
          <AuthRouter
            isAuthenticated={isAuthenticated}
            isGranted={adminType === ADMIN_TYPE.SUPER_ADMIN}
            path='/companymanagement'
            component={CompanyMain}
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  managerLogin: (params) => {
    dispatch(managerLogin(params))
  },
})

const Container = compose(connect(null, mapDispatchToProps))(App)

export default Container
