import { get, post, put as put_call, deleteRequest } from '../../services/request'
import { APP_HOST } from '../../config'
import { call, put, takeEvery } from 'redux-saga/effects'
import { auth } from '../../services/firebase'
import {
  createCouncilUserSuccess,
  createCouncilUserError,
  CREATE_COUNCIL_USER,

  updateCouncilUserSuccess,
  updateCouncilUserError,
  UPDATE_COUNCIL_USER,

  getModulesSuccess,
  getModulesError,
  GET_MODULES,

  getCouncilUsersSuccess,
  getCouncilUsersError,
  GET_COUNCIL_USERS,

  getCouncilUserSuccess,
  getCouncilUserError,
  GET_COUNCIL_USER,

  deleteCouncilUserSuccess,
  deleteCouncilUserError,
  DELETE_COUNCIL_USER,
  councilApiLoading,
  clearCouncil,
  CLEAR_COUNCIL,

  MANAGER_LOGIN,
  managerLoginSuccess,
  managerLoginError,

  GET_COUNCIL_USER_BY_EMAIL
} from './actions'

export function * clearCouncilUser () {
  yield put({ type: clearCouncil})
}

export function * getModules () {
  try {
    yield put({ type: councilApiLoading})
    const res = yield call(get, `${APP_HOST}/api/authority/modules`)
    yield put(getModulesSuccess(res))
  } catch (error) {
    yield put(getModulesError(error.message.replace('Error: ')))
    auth.signOut()
  }
}

export function * managerLogin ({ payload }) {
  try {
    yield put({ type: councilApiLoading})
    yield put(managerLoginSuccess({ data: payload.token}))
  } catch (error) {
    yield put({ type: councilApiLoading})
    yield put(managerLoginError(error.message.replace('Error: ')))
  }
}

export function * getCouncilUsers ({ payload }) {
  const { companyId } = payload
  try {
    if (!companyId) {
      throw new Error('Company ID is required!')
    }

    yield put({ type: councilApiLoading})
    const res = yield call(get, `${APP_HOST}/api/council/${companyId}/users`)
    yield put(getCouncilUsersSuccess(res))
  } catch (error) {
    yield put(getCouncilUsersError(error.message.replace('Error: ')))
  }
}

export function * getCouncilUser ({ payload }) {
  const { id, company_id, history } = payload
  try {
    if (!id) {
      throw new Error('User Group ID is required!')
    }

    yield put({ type: councilApiLoading})
    const res = yield call(post, `${APP_HOST}/api/council/${id}`, { company_id })
    yield put(getCouncilUserSuccess(res))
  } catch (error) {
    yield put(getCouncilUserError(error.message.replace('Error: ')))
    history.push('/')
  }
}

export function * getCouncilUserByEmail () {
  try {
    yield put({ type: councilApiLoading})
    const res = yield call(get, `${APP_HOST}/api/council-user`)
    yield put(getCouncilUserSuccess(res))
  } catch (error) {
    yield put(getCouncilUserError(error.message.replace('Error: ')))
  }
}

export function * createCouncilUser ({ payload }) {
  try {
    const params = {
      payload: {
        companyId: payload.company_id
      }
    }
    yield put({ type: councilApiLoading})
    const res = yield call(post, `${APP_HOST}/api/council`, payload)
    yield put(createCouncilUserSuccess(res))
    yield call(getCouncilUsers, params)
  } catch (error) {
    yield put(createCouncilUserError(error.message.replace('Error: ')))
  }
}

export function * updateCouncilUser ({ payload }) {
  try {
    const params = {
      payload: {
        companyId: payload.company_id
      } 
    }
    yield put({ type: councilApiLoading})
    const res = yield call(put_call, `${APP_HOST}/api/council`, payload)
    yield put(updateCouncilUserSuccess(res))
    yield call(getCouncilUsers, params)
  } catch (error) {
    yield put(updateCouncilUserError(error.message.replace('Error: ')))
  }
}

export function * deleteCouncilUser ({ payload }) {
  try {
    const { id, companyId } = payload
    const params = {
      payload: {
        companyId
      } 
    }
    yield put({ type: councilApiLoading})
    const res = yield call(deleteRequest, `${APP_HOST}/api/council/${id}`)
    yield put(deleteCouncilUserSuccess(res))
    yield call(getCouncilUsers, params)
  } catch (error) {
    yield put(deleteCouncilUserError(error.message.replace('Error: ')))
  }
}

export default function * sagas () {
  yield takeEvery(MANAGER_LOGIN, managerLogin)
  yield takeEvery(GET_MODULES, getModules)
  yield takeEvery(CLEAR_COUNCIL, clearCouncilUser)
  yield takeEvery(GET_COUNCIL_USERS, getCouncilUsers)
  yield takeEvery(GET_COUNCIL_USER, getCouncilUser)
  yield takeEvery(CREATE_COUNCIL_USER, createCouncilUser)
  yield takeEvery(UPDATE_COUNCIL_USER, updateCouncilUser)
  yield takeEvery(DELETE_COUNCIL_USER, deleteCouncilUser)
  yield takeEvery(GET_COUNCIL_USER_BY_EMAIL, getCouncilUserByEmail)
}
