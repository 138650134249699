import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { auth } from "../../services/firebase";
import { notify, errorNotification } from "../../services/notification";
import TaviLogo from "../../assets/images/TaviLogo.png";
import { isValidEmail } from "../../services/utils";

function ForgottenPassword() {
  const [email, setEmail] = useState("");
  const [makingRequest, setMakingRequest] = useState(false);

  const sendForgottenPasswordRequest = async (event) => {
    event.preventDefault();
    try {
      if (!email) {
        return errorNotification("Please fill missing fields");
      }
      if (email && !isValidEmail(email)) {
        return errorNotification("Please input valid email address!");
      }
      setMakingRequest(true);
      await auth.sendPasswordResetEmail(email, {
        url: `https://${process.env.REACT_APP_AUTH_DOMAIN}/login`,
        handleCodeInApp: true,
      }).then(() => {
        localStorage.setItem("email", email);
        notify(
          "Please check your email and click the provided link to reset your password."
        );
      }).catch(err => errorNotification(err.message))
    } catch (error: any) {
      errorNotification(error.toString());
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };

  return (
    <div className="forgot-page">
      <Link to="/" className="text-link">
        <img src={TaviLogo} className="App-logo" alt="logo" />
      </Link>
      <div className="forgot-container">
        <ToastContainer />
        <form className="forgot-form">
          <input
            type="email"
            className="input-form"
            name="userEmail"
            value={email}
            placeholder="Email Address"
            id="userEmail"
            style={{}}
            onChange={(event) => onChangeHandler(event)}
          />
          <button
            className="reset-btn submit-btn text-white"
            onClick={sendForgottenPasswordRequest}
            disabled={makingRequest}
          >
            Reset Password
          </button>
        </form>
        <p className="margin-0">
          <Link to="login" className="text-link">
            Go To Login
          </Link>
        </p>
      </div>
    </div>
  );
}

export default ForgottenPassword;
